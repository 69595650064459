// AUTH
export const USER_LOADING = 'AUTH/USER_LOADING'
export const USER_LOADED = 'AUTH/USER_LOADED'
export const AUTH_ERROR = 'AUTH/AUTH_ERROR'
export const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS'
export const LOGIN_FAIL = 'AUTH/LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'AUTH/LOGOUT_SUCCESS'
export const REGISTER_SUCCESS = 'AUTH/REGISTER_SUCCESS'
export const REGISTER_FAIL = 'AUTH/REGISTER_FAIL'
export const RESET_PASSWORD = 'AUTH/RESET_PASSWORD'
export const RESET_SUCCESS = 'AUTH/RESET_SUCCESS'
export const GO_TO_URL = 'AUTH/GO_TO_URL'
export const SET_USER_SUBSCRIPTION = 'AUTH/SET_USER_SUBSCRIPTION'
export const SET_BG_IMAGE = 'SET_BG_IMAGE'

// ERRORS
export const GET_ERRORS = 'GET_ERRORS'

// LOADING
export const SET_LOADING = 'SET_LOADING'
export const RESET_LOADING = 'RESET_LOADING'
