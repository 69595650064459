import React from 'react';

const BlogPage = () => {
    return (
        <div>
            Blog
        </div>
    );
};

export default BlogPage;